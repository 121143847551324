@use './variables';

.card-container {
  background: variables.$voting-white;
  box-shadow: 0 2px 4px rgba(1, 49, 64, 0.16);
  border-radius: 8px;
}

.card-inner-container {
  height: 100%;
  width: 100%;
}

.card-text-container {
  height: 100%;
  max-width: 50%;
  color: variables.$voting-dark;
  div:first-child, div:nth-child(2) {
    font-size: 36px;
    line-height: 44px;
    font-weight: bold;
  }
  div:nth-child(3) {
    margin-top: 6px;
    font-size: 20px;
    line-height: 24px;
    opacity: 0.8;
  }
}

.card-image {
  height: 100%;
  background: no-repeat center;
  background-size: cover;
  background-position-x: center;
  border-radius: 8px;
}

@media screen and (max-width: 1250px) and (min-width: 924px) {
  .card-text-container {
    div:first-child, div:nth-child(2) {
      font-size: 25px;
      line-height: 30px;
    }
    div:nth-child(3) {
      margin-top: 4.2px;
      font-size: 13.8px;
      line-height: 16.5px;
    }
  }
}

@media screen and (max-width: 924px) {
  .card-text-container {
    div:first-child, div:nth-child(2) {
      font-size: 23px;
      line-height: 31.5px;
    }
    div:nth-child(3) {
      font-size: 16px;
      line-height: 19px;
    }
  }
}

@media screen and (max-width: 415px) {
  .card-text-container {
    div:first-child, div:nth-child(2) {
      font-size: 19px;
      line-height: 25.2px;
    }
    div:nth-child(3) {
      font-size: 12.8px;
      line-height: 15.2px;
    }
  }
}
