/* You can add global styles to this file, and also import other style files */
@use "assets/styles/snack";
@use "assets/styles/card";
@use "assets/styles/spinner";
@import "~bootstrap/dist/css/bootstrap.css";

html, body {
  height: 100%;
}

body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
  background-color: #374F69;
}

.spinner-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: calc(100% - 76px);
  @media screen and (max-width: 924px) {
    height: calc(100% - 116.66px);
  }
}
