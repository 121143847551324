@use './variables';

snack-bar-container {
  background-color: variables.$voting-dark !important;
  simple-snack-bar {
    span {
      color: variables.$voting-white;
    }
  }
}

