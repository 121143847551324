@use './variables';

mat-spinner {
  height: 75% !important;

  svg {
    height: 100% !important;

    circle {
      height: 100% !important;
      stroke: variables.$voting-white !important;
    }
  }
}
